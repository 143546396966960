<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Nama"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: No. Tel -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="No. Tel"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Address -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Alamat"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="userData.address"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gereja -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Wilayah Gereja GPIB"
            label-for="church-jemaat"
          >
          <v-select
              v-model="userData.church_jemaat_id"
              :options="churchJemaat"
              :reduce="val => val.value"
              :clearable="true"
              input-id="church-jemaat"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gereja Lain -->
        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="Gereja Lain"
            label-for="church_name"
          >
            <b-form-input
              id="church_name"
              v-model="userData.church_name"
            />
          </b-form-group>
        </b-col>

      <!-- Field: Photo -->
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Photo"
          label-for="photo"
        >
          <b-form-file
            id="photo"
            v-model="userData.photo_blob"
            accept="image/*"
            placeholder="Pilih foto"
          />
        </b-form-group>

        <b-img v-if="userData.photo" :src="userData.photo" fluid thumbnail />
      </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :options="roles"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- new password -->
        <b-col cols="12" md="3">
          <b-form-group
            label-for="account-new-password"
            label="Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="userData.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col cols="12" md="3">
          <b-form-group
            label-for="account-retype-new-password"
            label="Ketik Ulang Password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Ketik Ulang Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <b-form-checkbox
              :checked="userData.status"
              v-model="userData.status"
              name="check-button"
              switch
              inline
            >
              Diverifikasi
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <div class="mt-2">
      <div style="float: left">
        <b-button
          variant="primary"
          @click="save()"
          :disabled="!isValid()"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span>{{ (isLoading) ? "Tunggu..." : "Simpan" }}</span>
        </b-button>
      </div>

      <div style="float: right">
        <b-button size="md" variant="outline-primary" @click="back()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Kembali</span>
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile
  },
  data() {
    return {
      isLoading: false,
      RetypePassword: "",
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      churchJemaat: [],
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    back() {
        this.$router.push('/users')
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    fetchChurchJemaat() {
      this.$http.get('/admin/v1/church_jemaat')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.churchJemaat = []

            currentData.forEach((item, index) => {
              this.churchJemaat.push({
                label: item.name + " - " + item.mupel.mupel,
                value: item,
              })
            }) 
          }
       })
    },
    save() {
      this.isLoading = true

      const requestData = new FormData()

      requestData.append('name', this.userData.name)
      requestData.append('email', this.userData.email)
      requestData.append('phone', this.userData.phone)

      if(this.userData.church_name) {
        requestData.append('church_name', this.userData.church_name)
      }

      if(this.userData.church_jemaat_id) {
        requestData.append('church_jemaat_id', this.userData.church_jemaat_id.id)
      }

      requestData.append('address', this.userData.address)
      requestData.append('role', this.userData.role)
      requestData.append('active', this.userData.active)
      requestData.append('password', this.userData.password)

      if(this.userData.photo_blob !== null) {
        requestData.append('photo', this.userData.photo_blob)
      }

      const requestHeader = { headers: { 'Content-Type': 'multipart/form-data' }}

      if(this.userData.id) {
        this.$http.put('/admin/v1/users/' + this.userData.id, requestData, requestHeader)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

            }

            this.isLoading = false
        })
      }
      else {
        this.$http.post('/admin/v1/users', requestData)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$router.replace("/users")
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Sukses`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: message,
                    },
                  })
                })
            }

            this.isLoading = false
        })
      }
    },
    isValid() {
      return this.userData.name != "" && this.userData.email != "" && this.userData.role != "" && this.userData.password !== "" && this.RetypePassword !== "" && this.userData.password === this.RetypePassword
    },
  },
  created() {
    this.fetchChurchJemaat()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  height: 40px;
}
</style>
