<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <div v-if="!(userData && roles)" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>

    <user-add-account
      v-if="userData && roles"
      :user-data="userData"
      :roles="roles"
      class="mt-2 pt-75"
    />
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import UserAddAccount from './UserAddAccount.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,

    UserAddAccount,
  },
  data() {
    return {
      userData: null,
      roles: null,
    }
  },
  methods: {
    fetchRoles() {
      this.$http.get('/admin/v1/roles')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.roles = []

            currentData.forEach((item, index) => {
              this.roles.push({
                label: item.name,
                value: item.name
              })
            })
            
          }
      })
    },

    setUserData() {
      this.userData = {
        id: null,
        name: "",
        email: "",
        photo_blob: null,
        photo: null,
        phone: "",
        church_name: "",
        church_jemaat_id: null,
        address: "",
        status: true,
        role: "User",
        password: ""
      }
    }
  },
  created() {
    this.setUserData()
    this.fetchRoles()
    // this.fetchCompanies()
  },
}
</script>
